/* eslint-disable no-restricted-globals */
import { Route, Routes } from "react-router-dom";
import Landing from "../pages/landing";

const Router = () => {
    return (
        <Routes>
            <Route>
                {/* Unprotected Routes [home, about, privacy, login, signup, forgot password] */}
                <Route
                    path="/"
                    element={<Landing />}
                />
                <Route
                    path="*"
                    element={<h1>404, page not found</h1>}
                />
            </Route>

            <Route
                path="*"
                element={<h1>404, page not found</h1>}
            />
        </Routes>
    );
};

export default Router;
