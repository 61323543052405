import LogoName from "../../assets/Landing/logo_name_text.svg";
import LandingPhone from "../../assets/Landing/landing_phone.svg";
import { FacebookFill, InstagramFill, TiktokFill } from "akar-icons";
import { Link } from "react-router-dom";

const Landing = () => {
    return (
        <div className="flex flex-col lg:flex-row mx-6 lg:mx-48 gap-6 items-center">
            <div className="flex flex-col flex-1 items-center lg:items-start text-center lg:text-left w-full">
                <img
                    src={LogoName}
                    className="w-72 lg:w-96 aspect-video mb-8 "
                    alt="logo.svg"
                />
                <h1 className="text-4xl lg:text-6xl font-extrabold leading-none">
                    It takes
                    <br />
                    <span className="text-accent">someone special</span>
                </h1>
                <h2 className="text-xl lg:text-3xl mb-6 lg:mb-10 font-medium text-center ">
                    To teach someone special
                </h2>
                <div className="flex bg-card p-5 lg:p-7 rounded-md items-center justify-center lg:justify-start">
                    <h4 className="mr-4">Get in touch</h4>
                    <div className="flex gap-4">
                        <div className="border-2 border-accent-2 rounded-full p-1.5 cursor-pointer hover:bg-white/15 ease-linear transition">
                            <Link
                                to={"https://www.instagram.com/kinderhausbh"}
                                target="_blank"
                            >
                                <InstagramFill className="text-accent-2 w-5 h-5" />
                            </Link>
                        </div>
                        <div className="border-2 border-accent-2 rounded-full p-1.5 cursor-pointer hover:bg-white/15 ease-linear transition">
                            <FacebookFill className="text-accent-2 w-5 h-5" />
                        </div>
                        <div className="border-2 border-accent-2 rounded-full p-1.5 cursor-pointer hover:bg-white/15 ease-linear transition">
                            <TiktokFill className="text-accent-2 w-5 h-5" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center lg:justify-end flex-1 mt-6 lg:mt-0">
                <img
                    src={LandingPhone}
                    className="w-48 lg:w-auto lg:min-w-[200px]"
                    alt="phone.png"
                />
            </div>
        </div>
    );
};

export default Landing;
